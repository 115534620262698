export enum WalletTransactionType {
  Deposit = "DEPOSIT",
  Withdraw = "WITHDRAW",
  Subscription = "SUBSCRIPTION",
  FeePerTrade = "FEE-SUBSCRIPTION",
  ReferralReward = "REFERRAL-REWARD-CASHBACK"
}

export abstract class WalletTransaction {
  constructor(
    public date: number,
    public type: WalletTransactionType,
    public amount: number,
    public fee: number
  ) { }
}

export class DepositWalletTransaction extends WalletTransaction {
  constructor(
    public date: number,
    public type: WalletTransactionType,
    public amount: number,
    public fee: number,
    public txId: string
  ) {
    super(date, type, amount, fee);
  }
}

export class WithdrawWalletTransaction extends WalletTransaction {
  constructor(
    public date: number,
    public type: WalletTransactionType,
    public amount: number,
    public fee: number,
    public hashId: string,
    public toAddress: string
  ) {
    super(date, type, amount, fee);
  }
}

export const examples: WalletTransaction[] = [
  new DepositWalletTransaction(1635759652.6366935, WalletTransactionType.Deposit, 146.5495483464911, 2.700451653508918, "0xc25a1361835f3a05580db05d3e611ea6f45cf24c9296dc1c9a55e3d95519e6c8"),
  new DepositWalletTransaction(1635759663.2476735, WalletTransactionType.Deposit, 96.79954834649108, 2.700451653508918, "0xc25a1361835f3a05580db05d3e611ea6f45cf24c9296dc1c9a55e3d95519e6c8"),
  new WithdrawWalletTransaction(1635759763.9003131, WalletTransactionType.Withdraw, 47.29954834649108, 2.700451653508918, "0xb53346003007a8c64d868d517f4a2b551ae67fe47a53f13b311df30b67f93231", "0x63763A3c5a37B310DB632Ab634A3bd20c69bEb47")
]