var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200","fullscreen":_vm.expanded},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold mb-0 pb-0"},[_vm._v(" Wallet And Security Details Of "+_vm._s(_vm.user.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.expanded ? "mdi-arrow-collapse" : "mdi-arrow-expand")+" ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-layout',{staticClass:"px-7 mt-5 text-start",attrs:{"column":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Wallet Details")]),_c('v-layout',{staticClass:"mt-2",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("Active Wallet Balance:")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.user.token.balance ? _vm.user.token.balance : 0)+" OBT ")])]),_c('v-layout',{staticClass:"mt-1",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("Pending Wallet Balance:")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.user.token.pending_balance)+" OBT ")])]),_c('v-layout',{staticClass:"mt-1",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("OB Wallet Address:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.user.token.address))])])],1),_c('v-col',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Security Details")]),_c('v-layout',{staticClass:"mt-2",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("2FA:")]),_c('span',{staticClass:"ml-2 "},[_vm._v(" "+_vm._s(_vm.user.OTP.enabled ? "Enabled" : "Disabled")+" ")]),(
                _vm.user.OTP.enabled &&
                  _vm.isFunctionalityAllowedForUser('otp', 'modify')
              )?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","depressed":"","x-small":""},on:{"click":function($event){return _vm.disableOTP(_vm.user.email)}}},[_vm._v(" Disable ")]):_vm._e()],1),_c('v-layout',{staticClass:"mt-1",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("Security Question:")]),_c('span',{staticClass:"ml-2 "},[_vm._v(" "+_vm._s(_vm.user.OTP.secret_question)+" ")])]),_c('v-layout',{staticClass:"mt-1",attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"text-label"},[_vm._v("Security Question Answer:")]),_c('span',{staticClass:"ml-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.user.OTP.secret_question_answer)+" ")])])],1)],1),_c('div',{staticClass:"mt-6 font-weight-bold"},[_vm._v("Transaction History")]),_c('v-tabs',{model:{value:(_vm.selectedTransactionTypeTab),callback:function ($$v) {_vm.selectedTransactionTypeTab=$$v},expression:"selectedTransactionTypeTab"}},[_c('v-tab',[_vm._v("Deposits ("+_vm._s(_vm.depositTransactionHistory.length)+")")]),_c('v-tab',[_vm._v("Withdrawals ("+_vm._s(_vm.withdrawalTransactionHistory.length)+")")]),_c('v-tab',[_vm._v(" Subscription ("+_vm._s(_vm.subscriptionTransactionHistory.length)+") ")])],1),_c('v-tabs-items',{model:{value:(_vm.selectedTransactionTypeTab),callback:function ($$v) {_vm.selectedTransactionTypeTab=$$v},expression:"selectedTransactionTypeTab"}},_vm._l((3),function(section){return _c('v-tab-item',{key:section},[_c('v-data-table',{attrs:{"header-color":"primary","headers":_vm.headers(section),"options":_vm.transactionHistoryOptions,"custom-sort":_vm.sortByDate,"items":_vm.dataset(section),"loading":_vm.isLoading,"loading-text":"Loading transaction history..."},on:{"update:options":function($event){_vm.transactionHistoryOptions=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateFromAPI(item.date)))])]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" OBT")])]}},{key:"item.fee",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(Number(item.fee) + (Number(item.fee) + Number(item.amount)) / 99.5 / 2)+" OBT ")])]}}],null,true)})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }