



















































































































import User from "@/utility/user";
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import { sortItems } from "@/utils/sortingUtils";
import {
  WalletTransaction,
  WalletTransactionType
} from "@/utility/wallet/walletTransaction";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";
import { showSuccessSnackbar } from "@/utils/snackbarUtils";

@Component({
  mixins: [DateFormatterMixin, PermissionCheckerMixin]
})
export default class ChangeUserSubscriptionPlanDialog extends Vue {
  // 🚩 Flag which determines whether the dialog should be shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The user which should have their subscription plan changed
  @Prop()
  public user!: User;

  // 🚩 Flag for whether the dialog is expanded to a fullscreen
  private expanded: boolean = false;

  // 📦 The deposit history of the currently selected user
  private get depositTransactionHistory(): WalletTransaction[] {
    return this.user.token_transactions.filter(
      (transaction: WalletTransaction) =>
        transaction.type === WalletTransactionType.Deposit ||
        transaction.type === WalletTransactionType.ReferralReward
    );
  }

  // 📦 The withdrawal history of the currently selected user
  private get withdrawalTransactionHistory(): WalletTransaction[] {
    return this.user.token_transactions.filter(
      (transaction: WalletTransaction) =>
        transaction.type === WalletTransactionType.Withdraw
    );
  }

  // 📦 The subscription history of the currently selected user
  private get subscriptionTransactionHistory(): WalletTransaction[] {
    return this.user.token_transactions.filter(
      (transaction: WalletTransaction) =>
        transaction.type === WalletTransactionType.Subscription ||
        transaction.type === WalletTransactionType.FeePerTrade
    );
  }

  // 📦 The table dataset based on the section
  private dataset(section: number) {
    switch (section) {
      case 1:
        return this.depositTransactionHistory;
      case 2:
        return this.withdrawalTransactionHistory;
      case 3:
        return this.subscriptionTransactionHistory;
    }
  }

  // 📦 The table headers based on the section
  private headers(section: number) {
    switch (section) {
      case 1:
        return this.depositTransactionHistoryHeaders;
      case 2:
        return this.withdrawalTransactionHistoryHeaders;
      case 3:
        return this.subscriptionTransactionHistoryHeaders;
    }
  }

  // 🗻 Table headers displayed in the deposit transactions
  private depositTransactionHistoryHeaders = [
    { text: "Date", value: "date" },
    { text: "Type", value: "type" },
    { text: "Amount", value: "amount" },
    { text: "Fee", value: "fee" }
  ];

  // 🗻 Table headers displayed in the withdraw transactions
  private withdrawalTransactionHistoryHeaders = [
    { text: "Date", value: "date" },
    { text: "Type", value: "type" },
    { text: "Hash Id", value: "hash_id", sortable: false },
    { text: "Amount", value: "amount" },
    { text: "Fee", value: "fee" },
    { text: "To Address", value: "to_address", sortable: false }
  ];

  // 🗻 Table headers displayed in the subscription transactions
  private subscriptionTransactionHistoryHeaders = [
    { text: "Date", value: "date" },
    { text: "Type", value: "type" },
    { text: "Amount", value: "fee" },
    { text: "Price", value: "price" }
  ];

  // 📦 The currently selected tab (withdrawal/deposit)
  private selectedTransactionTypeTab: number = 0;

  // 📦 Pagination object used for default sorting the deposit transaction history
  private transactionHistoryOptions = {
    sortBy: ["date"],
    sortDesc: [false],
    mustSort: true,
    itemsPerPage: 10
  };

  /**
   * 📋 Custom sorting function that orders the deposit transactions by date
   */
  private sortByDate(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("date", items, index, isDesc);
  }

  /**
   * ❌ Disables the selected user's OTP
   */
  private disableOTP(email: string) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/disableotp ", {
        username: email,
        is_disabled: true
      })
      .then((response: any) => {
        showSuccessSnackbar("OTP was successfully disabled!");
        this.user.OTP.enabled = false;
      });
  }
}
